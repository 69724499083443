// root variables present on all themes

.root--mrs-ui {
  // typography
  --font-family: 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  --base-line-height: 1.5;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-semiBold: 500;
  --font-weight-bold: 600;
  --font-weight-heavy: 700;
  --font-weight-black: 800;

  --font-weight-heading: var(--font-weight-semiBold);

  --base-font-size: 12px;
  --small-font-size: 11px;
  --large-font-size: 15px;

  --h1: var(--font-weight-heading) 24px/1.4em var(--font-family);
  --h2: var(--font-weight-heading) 18px/1.4em var(--font-family);
  --h3: var(--font-weight-heading) 16px/1.4em var(--font-family);
  --h4: var(--font-weight-heading) var(--large-font-size) / 1.4em var(--font-family);
  --h5: var(--font-weight-heading) var(--base-font-size) / 1.4em var(--font-family);
  --h6: var(--font-weight-heading) var(--small-font-size) / 1.4em var(--font-family);

  --icon-stroke-width: 1.8px;

  // scaling
  --scale0: 2px;
  --scale100: 4px;
  --scale200: 6px;
  --scale300: 8px;
  --scale400: 10px;
  --scale500: 12px;
  --scale550: 14px;
  --scale600: 16px;
  --scale650: 18px;
  --scale700: 20px;
  --scale750: 22px;
  --scale800: 24px;
  --scale850: 28px;
  --scale900: 32px;
  --scale950: 36px;
  --scale1000: 40px;
  --scale1200: 48px;
  --scale1400: 56px;
  --scale1500: 60px;
  --scale1600: 64px;
  --scale1800: 72px;

  // sizes for buttons, inputs, etc.
  --size-large: var(--scale1200);
  --size-default: var(--scale900);
  --size-compact: var(--scale850);
  --size-mini: var(--scale700);

  // grid system
  --gap-large: var(--scale900);
  --gap-default: var(--scale600);
  --gap-compact: var(--scale600);
  --gap-mini: var(--scale400);

  --topbar-height: var(--scale1400);
  --sidebar-width: 224px;

  --local-side-nav-width: 224px;

  --container-full: 100%;
  --container-compact: 936px;
  --container-medium: 1266px;

  // expressiveness
  // elevation --> surface backgrounds for elevation levels are in theme stylesheets (i.e. dark/light mode)
  --shadow1: 0px 3px 3px 0 rgba(0, 0, 0, 0.1); // level 1 elevated content, such as elevated cards
  --shadow2: 0 2px 4px rgb(0 0 0 / 13%); // level 2 elevated content, such as tooltips, dropdown/popup menus
  --shadow3: 0 6px 28px 1px rgba(0, 0, 0, 0.16); // level 3 elevated content, such as toasts and sidesheets
  --shadow4: 0 0 18px rgb(0 0 0 / 40%); // use for level 4 elevated content, mainly modals

  --card-shadow: 0 0.8px 2px 0 rgb(0 0 0 / 17%), 0 0 0 1px rgb(0 0 0 / 4%);

  --z-base: 0; // base content
  --z1: 1;
  --z2: 9; // Popovers (e.g. select dropdown, tooltips, etc.)
  --z3: 99; // Sticky content e.g. sticky save bar
  --z4: 999; // modals, sidesheet, toasts etc.

  // shadows and radii
  --input-shadow: inset 0px -1px 0px var(--shade300);
  --input-focus-shadow: inset 0px -2px 0px var(--primary-color);
  --input-shadow-error: inset 0px -2px 0px var(--danger-color);

  --radius1: 4px;
  --radius2: 6px;
  --radius3: 6px;
  --radius4: 6px;
  --pill-radius: 500px;

  // animations
  --animation-timing100: 100ms;
  --animation-timing200: 200ms;
  --animation-timing300: 300ms;
  --animation-timing400: 400ms;
  --animation-timing500: 500ms;
  --animation-timing1000: 1000ms;

  --hover-transition: 0.1s cubic-bezier(0.25, 0.1, 0.25, 1);
  --button-hover-transition: background-color var(--animation-timing100)
    cubic-bezier(0.25, 0.1, 0.25, 1);
  --focus-shadow-transition: none;
}
