/* this is the css file for override styles of date picker component */

.react-datepicker {
  width: 108%;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day--selected {
  background-color: #0070c9;
  color: white !important;
  font-weight: bold;
}

.react-datepicker__header {
  background-color: #0070c9;
}

.react-datepicker__day-name {
  color: white;
  font-weight: bold;
}

.react-datepicker__header .react-datepicker__header--custom {
  font-weight: bold;
}

.react-datepicker__today-button {
  background-color: #0070c9;
  color: white;
  width: 40%;
  border-radius: 0.3rem;
  margin: 5px auto;
  font-size: 0.75rem;

  &:hover {
    background-color: darken(#0070c9, 5%);
  }
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
  border-bottom-color: #0070c9;
}
