// base styles

html {
  font-size: 16px;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0px;
  border-style: solid;
  border-color: var(--border-color);
}
body {
  font-family: var(--font-family);
  color: var(--text-color);
  background: var(--background-color);
  font-weight: var(--font-weight-normal);
  min-width: 980px;
  overscroll-behavior: none;
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
}
body.scrolling-locked {
  height: 100%;
  overflow: hidden;
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
b,
strong {
  font-weight: var(--font-weight-bold);
}
svg {
  stroke-width: var(--icon-stroke-width);
}
blockquote,
body,
button,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
ul {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
p + p {
  margin-top: 20px;
}
h1 + p,
h2 + p,
h3 + p,
h4 + p,
h5 + p,
h6 + p,
p + p,
p + ul,
ul + p,
p + ul {
  margin-top: 0.333em;
}
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 1.5em;
}
::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}
