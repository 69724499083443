// dark theme styles
// * this is currently in use for dark surfaces (e.g. top bar), not for full dark mode functionality

.theme--dark {
  background-color: var(--base-color);

  // main surface background & contrast color used for text
  --background-color: var(--base-color);
  --contrast-color: #fff;
  --overlay: rgba(0, 0, 0, 0.5);

  --base-color: #1d3649;
  --shade1: #0a4169;
  --shade2: #07558d;
  --shade3: #046bb6;
  --shade4: #5596e6;

  // applications of shades
  --text-color: var(--contrast-color);

  // primary, interaction & accent colors
  --primary-color: #7cc7ff;
  --primary-color50: #eff3fe;
  --primary-color100: #7cc7ff;
  --primary-color200: #5aaafa;
  --primary-color300: #5596e6;
  --primary-color400: #7cc7ff;
  --primary-color500: #07558d;
  --primary-color600: #0a4169;
  --primary-color700: #1d3649;

  --focus-outline: 2px solid var(--primary-color);
}
