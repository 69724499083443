// primary light theme styles

.theme--light {
  background-color: var(--shade100);
  --transparent: rgba(0, 0, 0, 0);

  // main surface background & contrast color used for text
  --background-color: #fff;
  --contrast-color: #24292e;
  --overlay: rgba(0, 0, 0, 0.5);

  // background colors for different elevations (used for dark theme --> light theme is all white)
  // 1: elevated cards
  // 2: dropdowns, floating action buttons & tooltips
  // 3: toasts/notifications
  // 4: modals/sidesheets
  --elevated-surface-background1: #fff;
  --elevated-surface-background2: #fff;
  --elevated-surface-background3: #fff;
  --elevated-surface-background4: #fff;

  // Color scheme logic
  // 50 = light background shade, 100 = hover color, 200 = focus color, etc

  // shades of gray --> used for backgrounds, borders, light text, etc.
  --base-color: #fff;
  --shade50: #fafbfc;
  --shade100: #f0f3f6;
  --shade200: #dfe3e6;
  --shade300: #d1d7db;
  --shade400: #aaadaf;

  // applications of shades
  --text-color: var(--contrast-color);
  --text-light: #66707a;
  --text-muted: var(--shade400);
  --border-color: var(--shade200);

  // primary, interaction & accent colors
  --accent-color: #d3ecff;

  --primary-color: #0070c9;
  --primary-color50: #f1f8ff;
  --primary-color100: #dbedff;
  --primary-color200: #5aaafa;
  --primary-color300: #5596e6;
  --primary-color400: #0070c9;
  --primary-color500: #075a96;
  --primary-color600: #0a4169;
  --primary-color700: #1d3649;

  --danger-color: #e11900;
  --danger-color50: #fdf1ef;
  --danger-color100: #eeb9b2;
  --danger-color200: #f1998e;
  --danger-color300: #e85c4a;
  --danger-color400: #e11900;
  --danger-color500: #c71a04;
  --danger-color600: #870f00;
  --danger-color700: #5a0a00;

  --success-color: hsl(136.8, 56.2%, 41.2%);
  --success-color50: #e6f2ed;
  --success-color100: #addec9;
  --success-color200: #66d19e;
  --success-color300: #06c167;
  --success-color400: hsl(136.8, 56.2%, 41.2%);
  --success-color500: hsl(137.4, 54.9%, 38.2%);
  --success-color600: #03582f;
  --success-color700: #10462d;

  --warning-color: #f5a623;
  --warning-color50: #fffaf0;
  --warning-color100: #fff2d9;
  --warning-color200: #ffe3ac;
  --warning-color300: #ffcf70;
  --warning-color400: #ffc043;
  --warning-color500: #bc8b2c;
  --warning-color600: #997328;
  --warning-color600: #674d1b;

  --active-link-line-color: #e47911;

  // this is the contrast color (e.g. for text) on top of the interaction/feedback colors
  --interaction-contrast: #fff;

  --skeleton-gradient: linear-gradient(
    90deg,
    var(--shade200) 0px,
    var(--shade100) 50%,
    var(--shade200) 100%
  );

  // states/accessibility

  --focus-outline: 2px solid var(--primary-color);

  // buttons && inputs
  --focus-shadow: 0 0 0px 3px rgba(4, 107, 182, 0.25);
  --focus-shadow-error: 0 0 0 3px #e1190030;
  --default-inset-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
  --focus-outline: 0px dotted var(--primary-color);

  --default-btn-shadow: 0 1px 0 rgba(27, 31, 35, 0.04),
    inset 0 1px 0 rgba(255, 255, 255, 0.25);
  --primary-btn-shadow: 0 1px 0 rgba(27, 31, 35, 0.1),
    inset 0 1px 0 rgba(255, 255, 255, 0.03);

  --btn-border-color: #1b1f2326;
  --btn-border-color-active: rgba(27, 31, 35, 0.1);
  --default-btn-bg: hsl(210, 25%, 98.4%);
  --default-btn-bg-hover: hsl(220, 14.3%, 95.9%);
  --default-btn-bg-active: #ebecf0;
  --btn-active-shadow: inset 0 1px 0 rgba(20, 70, 32, 0.2);

  --input-inset-shadow: inset 0 0.4px 2px rgba(0, 0, 0, 0.06);
  --input-bg: #fafbfc;
}
